<template>
  <div>
    <b-row v-if="photo.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="photo"
            :fields="fields"
            @row-dblclicked="open"
            @row-contextmenu="handleContextMenuEvent"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(url)="data">
              <div>
                <img :src="data.item.url" style="width: 100%" alt="" />
              </div>
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link
                class="d-flex align-items-center"
                @click="getPhotoTreatment()"
                v-b-modal.photo_treatment
              >
                <feather-icon icon="EditIcon" size="16" />
                <span>Редактировать</span>
              </b-link>
              <b-link
                class="d-flex align-items-center"
                @click="getChangeType()"
                v-b-modal.change_type
              >
                <feather-icon icon="EditIcon" size="16" />
                <span>Изменить тип</span>
              </b-link>
              <b-link class="d-flex align-items-center" @click="getBlock()">
                <feather-icon icon="EditIcon" size="16"></feather-icon>
                <span>Блокировать</span>
              </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueContext from "vue-context";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  props: ["photo"],
  components: {
    VueContext,
    FeatherIcon,
  },
  data() {
    return {
      data: [],
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        {
          key: "performer_assign_p_c_id",
          label: "id",
          thStyle: { width: "30px" },
        },
        {
          key: "start_date",
          label: "Дата загрузки",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "url",
          label: "Файл",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "type_photo_control_name",
          label: "Тип",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "comment",
          label: "Комментария",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "status_name",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "check_user_login",
          label: "Создал",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.data = item;
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;

      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();

      cellSelect();
    },
    open(item) {
      this.$emit("open", item);
    },
    getPhotoTreatment() {
      this.$emit("getPhotoTreatment", this.data);
    },
    getChangeType() {
      this.$emit("getChangeType", this.data);
    },
    getBlock() {
      this.$emit("getBlock");
    },
  },
};
</script>

<style lang="scss" scoped></style>
